import {
  TextField,
  Icon,
  TextFieldProps,
  Popover,
  Listbox,
  ListboxProps,
} from "@shopify/polaris"
import { useCallback, useState, VFC } from "react"

import { ClockIcon } from "@shopify/polaris-icons";

type TimeTextFieldProps = Omit<TextFieldProps, "autoComplete">

const times = [...Array(24)].flatMap((_, index) => {
  const hour = String(index).padStart(2, "0")
  return [`${hour}:00`, `${hour}:30`]
})

export const TimeTextField: VFC<TimeTextFieldProps> = ({
  onChange,
  onBlur,
  onFocus,
  value,
  ...otherProps
}) => {
  const [popoverActive, setPopoverActive] = useState(false)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  )
  const handleTextChange: TextFieldProps["onChange"] = useCallback(
    (val, id) => {
      onChange?.(val, id)
    },
    [onChange]
  )
  const handleTextFocus: TextFieldProps["onFocus"] = useCallback(() => {
    onFocus?.()
    setPopoverActive(true)
  }, [onFocus])
  const handleTextBlur: TextFieldProps["onBlur"] = useCallback(() => {
    onBlur?.()
  }, [onBlur])
  const handleListItemSelect: NonNullable<ListboxProps["onSelect"]> =
    useCallback((val) => {
      handleTextChange(val, "")
      setPopoverActive(false)
    }, [])

  const optionsMarkup = times.map((time) => {
    return (
      <Listbox.Option
        key={`${time}`}
        value={time}
        selected={value === time}
        accessibilityLabel={time}
      >
        {time}
      </Listbox.Option>
    )
  })

  return (
    <Popover
      activator={
        <TextField
          prefix={<Icon source={ClockIcon} />}
          autoComplete="off"
          onChange={handleTextChange}
          value={value}
          onBlur={handleTextBlur}
          onFocus={handleTextFocus}
          {...otherProps}
        />
      }
      active={popoverActive}
      onClose={togglePopoverActive}
    >
      <Popover.Section>
        <Listbox onSelect={handleListItemSelect}>{optionsMarkup}</Listbox>
      </Popover.Section>
    </Popover>
  );
}
