import {
  ActionList,
  Combobox,
  Listbox,
  IconableAction,
  DisableableAction,
  ListboxProps,
} from "@shopify/polaris"
import { OptionDescriptor } from "@shopify/polaris/build/ts/latest/src/types"
import { FC } from "react"
import { ReactElement, useCallback, useMemo, VFC } from "react"

interface AutocompleteProps {
  options: OptionDescriptor[]
  selected: string[]
  textField: ReactElement
  allowMultiple?: boolean
  onSelect(selected: string[]): void
  action?: IconableAction & DisableableAction
}

const Autocomplete: VFC<AutocompleteProps> & {
  TextField: typeof Combobox.TextField
} = ({ options, selected, textField, allowMultiple, onSelect, action }) => {
  const optionsMarkup = useMemo(() => {
    return options.length > 0
      ? options.map((option) => (
        <Option
          key={option.id || option.value}
          {...option}
          selected={selected.includes(option.value)}
          disabled={option.disabled}
        />
      ))
      : null
  }, [options, selected])

  const updateSelection = useCallback(
    (newSelection: string) => {
      if (allowMultiple) {
        if (selected.includes(newSelection)) {
          onSelect(selected.filter((option) => option !== newSelection))
        } else {
          onSelect([...selected, newSelection])
        }
      } else {
        onSelect([newSelection])
      }
    },
    [allowMultiple, onSelect, selected]
  )

  const actionsMarkup = action ? <ActionList items={[action]} /> : null

  return (
    <Combobox activator={textField} allowMultiple={allowMultiple}>
      <ListboxWrapper>
        {actionsMarkup}
        <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
      </ListboxWrapper>
    </Combobox>
  )
}

// force for type check
const ListboxWrapper: FC<ListboxProps> = ({ children, ...props }) => {
  return <>{children}</>
}

const Option: VFC<OptionDescriptor & { selected: boolean }> = ({
  label,
  value,
  disabled,
  selected,
}) => {
  return (
    <Listbox.Option
      key={value}
      selected={selected}
      value={value}
      disabled={disabled}
    >
      <Listbox.TextOption selected={selected} disabled={disabled}>
        {label}
      </Listbox.TextOption>
    </Listbox.Option>
  )
}

Autocomplete.TextField = Combobox.TextField

export { Autocomplete, AutocompleteProps }
